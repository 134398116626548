<template>
  <div class="p-3">
    <appCheckBankruptcy
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckBankruptcy>
  </div>
</template>
<script>
import appCheckBankruptcy from "../../components/checkBankruptcy/checkBankruptcy.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckBankruptcy",

  components: {
    appCheckBankruptcy,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>